import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import { StringUtils } from '../utils/objectUtils';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel } from '@mui/material';
let moment = require('moment');

const MonthSelect = ({
    monthEndOrStart,
    onChange = null,
    defaultValue = null,
    placeholder = 'Select Date',
    width = '200px',
    className = null,
    isDisabled = false,
}: any) => {
    const [value, setValue] = useState(defaultValue || '');
    const [monthDates, setMonthDates] = useState([]);

    useEffect(() => {
        const monthOption = (monthCount: number) => {
            const date = moment().subtract(monthCount, 'months');
            const finalDate = StringUtils.equals(monthEndOrStart, 'start')
                ? date.startOf('month')
                : date.endOf('month');

            return {
                formattedDate: finalDate.format('MM/DD/YYYY'),
                rawDate: finalDate.format('YYYYMMDD'),
            };
        };

        let n = StringUtils.equals(monthEndOrStart, 'start') ? 1 : 0;
        let months = [];
        while (n < 12) {
            n++;

            months.push(monthOption(n));
        }

        setMonthDates(months);
    }, [monthEndOrStart]);

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange(e.target.value);
    };

    return (
        <div style={{ width }}>
            <FormControl size="small" fullWidth>
                <InputLabel>{placeholder}</InputLabel>
                <Select
                    fullWidth
                    label={placeholder}
                    name="monthEndSelect"
                    disabled={isDisabled}
                    onChange={handleChange}
                    value={value}
                    renderValue={(value) => <>{value.formattedDate}</>}>
                    {monthDates.map((option: any) => {
                        return (
                            <MenuItem key={option.rawDate} value={option}>
                                {option.formattedDate}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default MonthSelect;
