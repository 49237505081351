import { isNilOrEmpty } from '../../utils/objectUtils';
import { Node } from '../../types/Settings.types';

export const buildNodeFromPath = (currentNodes: Array<Node>, path: string) => {
    //determine the appropriate array to configure recursively
    let pathArray = path?.split('.');

    if (isNilOrEmpty(pathArray)) {
        pathArray = ['N/A'];
    }

    //grab or create the initial node
    let parentNode = addNodeIfNeeded(currentNodes, pathArray[0]);

    //remove that element from the array since it's handled
    pathArray.shift();

    //loop over any remainders + add recursively
    pathArray.forEach((path) => {
        parentNode = addNodeIfNeeded(parentNode.children, path);
    });

    //return the last node created, because that's where the data will go
    return parentNode;
};

const addNodeIfNeeded = (parentNode: Array<Node>, nodePath: string) => {
    let childNode = parentNode.find((child) => child.label === nodePath);

    if (!childNode) {
        childNode = {
            label: nodePath,
            children: [],
        };

        parentNode.push(childNode);
    }

    return childNode;
};
