import { API } from 'aws-amplify';

export const TestHarnessService = {
    getTHAudits: () => {
        return API.get(
            'datacorAPIWithCustAuth',
            '/workflow-test-harness/audits'
        );
    },
    getTHContacts: () => {
        return API.get(
            'datacorAPIWithCustAuth',
            '/workflow-test-harness/contacts'
        );
    },
    updateContact: (id, params) => {
        params['verifiedBy'] = null;
        params['verifiedAt'] = null;
        return API.put(
            'datacorAPIWithCustAuth',
            `/workflow-test-harness/contacts/${id}`,
            {
                body: params,
            }
        );
    },
    verifyContact: async (id) => {
        const path = `/workflow-test-harness/contacts/${id}/verify`;
        const result = await API.get('datacorAPIWithCustAuth', path);
        return result;
    },
    createContact: (params) => {
        return API.post(
            'datacorAPIWithCustAuth',
            '/workflow-test-harness/contacts',
            {
                body: params,
            }
        );
    },
    getContact: (id) => {
        return API.get(
            'datacorAPIWithCustAuth',
            `/workflow-test-harness/contacts/${id}`
        );
    },
};
