import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateRoleForCompanyMutation } from '../../../services/permissionsAndPreferences/permissionsAndPreferences.service';
import { useGetCompaniesByUserIdQuery } from '../../../services/users/users.service';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from '../../../store';

interface CreateRoleForm {
    companyId: string;
    name: string;
    note: string;
    status: string;
}

const useCreateRole = ({ handleClose }: any) => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const user = useSelector((state: RootState) => state.user);
    const [createRole, { isLoading: isLoadingRole }] =
        useCreateRoleForCompanyMutation();
    const { data: companyList, isLoading } = useGetCompaniesByUserIdQuery(
        user.userId
    );
    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
            note: yup.string().nullable().notRequired(),
            status: yup.string().notRequired(),
            companyId: yup.string().required(),
        })
        .required();

    const { handleSubmit, control, formState, setValue, register } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            name: '',
            note: '',
            companyId: '',
            status: 'ACTIVE',
        },
    });
    register('companyId');

    const onSubmit = async (form: CreateRoleForm) => {
        const body = {
            companyId: form.companyId,
            name: form.name,
            note: form.note,
            status: form.status,
        };

        createRole(body);
        handleClose(true);
    };

    const companySelectionChanged = (selectedValue: string) => {
        setSelectedCompany(selectedValue);
        setValue('companyId', selectedValue, { shouldValidate: true });
    };

    return {
        control,
        formState,
        isLoading,
        isLoadingRole,
        selectedCompany,
        companyList,
        companySelectionChanged,
        handleSubmit,
        onSubmit,
    };
};

export default useCreateRole;
