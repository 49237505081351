import { API } from 'aws-amplify';

export const SystemWorkflowsService = {
    list: () => {
        return API.get('datacorAPIWithCustAuth', '/workflow/systemWorkflows');
    },
    getSystemWorkflow: async (id) => {
        const data = await API.get(
            'datacorAPIWithCustAuth',
            `/workflow/systemWorkflows/${id}`
        );
        return data;
    },
    getSystemWorkflowFull: async (id) => {
        const data = await API.get(
            'datacorAPIWithCustAuth',
            `/workflow/systemWorkflows/${id}?full=true`
        );
        return data;
    },
};
