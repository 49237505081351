import React from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    Grid,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
} from '@mui/material';
import LoaderButton from '../../components/LoaderButton';

const WorkflowPortal = () => {
    const navToTestHarness = () => {};

    const testHarnessCard = () => {
        return (
            <Card sx={{ maxWidth: 500, height: '100%' }}>
                <CardHeader title="Workflow Test Harness"></CardHeader>
                <CardContent>
                    <Typography variant="subtitle1">
                        Developer tool to test and demonstrate workflow
                        functionality.
                    </Typography>
                    The Workflow Test Harness acts like an external application,
                    providing sample interaction and use of workflows.
                </CardContent>
                <CardActions>
                    <Link to="/workflow-test-harness/">
                        <LoaderButton isLoading={null} variant="primary">
                            Go
                        </LoaderButton>
                    </Link>
                </CardActions>
            </Card>
        );
    };

    const systemCard = () => {
        return (
            <Card sx={{ maxWidth: 500, height: '100%' }}>
                <CardHeader title="System Configuration"></CardHeader>

                <CardContent>
                    <Typography variant="subtitle1">
                        Developer tool to configure System Workflows.
                    </Typography>
                    The System Configuration area allows developers to define
                    integration with applications and modules. These other
                    systems are clients to the Workflow server (this
                    application).
                </CardContent>
                <CardActions>
                    <Link to="/workflow/apps/">
                        <LoaderButton isLoading={null} variant="primary">
                            Go
                        </LoaderButton>
                    </Link>
                </CardActions>
            </Card>
        );
    };

    const designerCard = () => {
        return (
            <Card sx={{ maxWidth: 500, height: '100%' }}>
                <CardHeader title="Workflow Designer" />

                <CardContent>
                    <Typography variant="subtitle1">
                        Administrator Tool to design Subscriber Workflows.
                    </Typography>
                    The Designer tool allows Datacor or Subscriber
                    administrators to configure workflows for their
                    organizations.
                </CardContent>
                <CardActions>
                    <Link to="/workflow/">
                        <LoaderButton isLoading={null} variant="primary">
                            Coming Soon...
                        </LoaderButton>
                    </Link>
                </CardActions>
            </Card>
        );
    };

    const dashboardCard = () => {
        return (
            <Card sx={{ maxWidth: 500, height: '100%' }}>
                <CardHeader title="Dashboard" />

                <CardContent>
                    The Workflow Dashboard shows in-process documents
                </CardContent>
                <CardActions>
                    <Link to="/workflow/">
                        <LoaderButton isLoading={null} variant="primary">
                            Coming Soon...
                        </LoaderButton>
                    </Link>
                </CardActions>
            </Card>
        );
    };

    const manualCard = () => {
        return (
            <Card sx={{ maxWidth: 500, height: '100%' }}>
                <CardHeader title="Manual Workflows" />

                <CardContent>
                    Initiate and process manual workflows.
                </CardContent>
                <CardActions>
                    <Link to="/workflow/">
                        <LoaderButton isLoading={null} variant="primary">
                            Coming Soon...
                        </LoaderButton>
                    </Link>
                </CardActions>
            </Card>
        );
    };
    const reports = () => {
        return (
            <Card sx={{ maxWidth: 500, height: '100%' }}>
                <CardHeader title="Reports" />
                <CardContent>
                    Kanban <br />
                    Bottlenecks <br />
                    Worflow Usage <br />
                    Coming Soon...
                </CardContent>
            </Card>
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    {testHarnessCard()}
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    {systemCard()}
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    {designerCard()}
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    {dashboardCard()}
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    {manualCard()}
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    {reports()}
                </Grid>
            </Grid>
        </>
    );
};

export default WorkflowPortal;
