import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import LoaderButton from '../../../components/LoaderButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { onError } from '../../../libs/errorLib';
import { TestHarnessService } from '../../../services/workflow/testHarness/testHarness.service';
import { dateTimeFormatter } from '../../../utils/formattingUtils';

const AddEditContact = ({ handleClose, id = null }) => {
    const schema = yup
        .object()
        .shape({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            email: yup.string().required(),
        })
        .required();

    const { control, register, handleSubmit, setValue, formState, getValues } =
        useForm({
            resolver: yupResolver(schema),
            mode: 'all',
            defaultValues: {
                firstName: '',
                lastName: '',
                email: '',
            },
        });

    const [selectedContact, setSelectedContact] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                if (id) {
                    setSelectedContact(await TestHarnessService.getContact(id));
                }
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    const onSubmit = async (form) => {
        setIsLoading(true);

        id ? handleUpdateContact(form) : handleCreateContact(form);
    };

    const isFormValid = () => {
        return formState.isValid;
    };

    useEffect(() => {
        if (selectedContact) {
            updateFields();
        }
    }, [selectedContact]);

    const updateFields = async () => {
        setValue('firstName', selectedContact.firstName, {
            shouldValidate: true,
        });
        setValue('lastName', selectedContact.lastName, {
            shouldValidate: true,
        });
        setValue('email', selectedContact.email, { shouldValidate: true });
    };

    const handleCreateContact = async (form) => {
        const body = {
            email: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
        };

        try {
            await TestHarnessService.createContact(body);
            setIsLoading(false);
            handleClose(true);
        } catch (e) {
            setIsLoading(false);
            return onError(e.message);
        }
    };

    const handleUpdateContact = async (form) => {
        const body = {
            email: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
        };

        try {
            await TestHarnessService.updateContact(id, body);
            setIsLoading(false);
            handleClose(true);
        } catch (e) {
            setIsLoading(false);
            return onError(e.message);
        }
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Contact</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={(field) => (
                                <TextField
                                    data-testid="firstName"
                                    label="First Name"
                                    name="firstName"
                                    variant="outlined"
                                    fullWidth
                                    autoFocus
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="lastName"
                            control={control}
                            render={(field) => (
                                <TextField
                                    label="Last Name"
                                    data-testid="lastName"
                                    name="lastName"
                                    variant="outlined"
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={(field) => (
                                <TextField
                                    label="Email"
                                    data-testid="email"
                                    name="email"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    disabled={!!id}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    {selectedContact?.verifiedBy != null ? (
                        <Typography
                            variant="caption"
                            sx={{ paddingLeft: '20px' }}>
                            Verified By: {selectedContact.verifiedBy} (
                            {dateTimeFormatter(selectedContact.verifiedAt)})
                        </Typography>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoaderButton
                    type="submit"
                    size="lg"
                    data-testid="updateContactBtn"
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isFormValid()}>
                    {id ? 'Update Contact' : 'Create Contact'}
                </LoaderButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditContact;
