import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { dateTimeFormatter } from '../../../utils/formattingUtils';
import { TestHarnessService } from '../../../services/workflow/testHarness/testHarness.service';
import { onError } from '../../../libs/errorLib';

const AuditList = () => {
    const [audits, setAudits] = useState(null);

    useEffect(() => {
        const onLoad = async () => {
            try {
                setAudits(await TestHarnessService.getTHAudits());
            } catch (e) {
                onError(e);
            }
        };

        onLoad();
    }, []);

    return (
        <>
            <h2> Audit Records </h2>
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowHeight="30"
                    rowData={audits}
                    domLayout={'autoHeight'}
                    onGridReady={(params) => params.api.sizeColumnsToFit()}>
                    <AgGridColumn
                        valueFormatter={dateTimeFormatter}
                        field="createdAt"
                    />
                    <AgGridColumn field="event" />
                    <AgGridColumn field="msg" />
                </AgGridReact>
            </div>{' '}
        </>
    );
};

export default AuditList;
