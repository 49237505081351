import { API } from 'aws-amplify';

export const AppsService = {
    list: () => {
        return API.get('datacorAPIWithCustAuth', '/workflow/apps');
    },
    update: (id, params) => {
        return API.put('datacorAPIWithCustAuth', `/workflow/apps/${id}`, {
            body: params,
        });
    },
    create: (params) => {
        return API.post('datacorAPIWithCustAuth', '/workflow/apps', {
            body: params,
        });
    },
    getApp: async (id) => {
        const data = await API.get(
            'datacorAPIWithCustAuth',
            `/workflow/apps/${id}`
        );
        return data;
    },
    getAppFull: async (id) => {
        const data = await API.get(
            'datacorAPIWithCustAuth',
            `/workflow/apps/${id}?full=true`
        );
        return data;
    },
};
