import React from 'react';
import './ChangeEmail.css';
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@mui/material';
import LoaderButton from '../../components/LoaderButton';
import useChangeEmailConfirmation from './hooks/useChangeEmailConfirmation';

const ChangeEmailConfirmation = () => {
    const {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateConfirmForm,
    } = useChangeEmailConfirmation();

    return (
        <div className="ChangeEmail">
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Confirmation Code"
                        type="tel"
                        autoFocus
                        value={fields.code}
                        id="code"
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Please check your email for the confirmation code.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        onClick={handleConfirmClick}
                        type="submit"
                        variant="contained"
                        isLoading={isConfirming}
                        disabled={!validateConfirmForm()}>
                        Send Confirmation
                    </LoaderButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangeEmailConfirmation;
