import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import LoaderButton from '../../../components/LoaderButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { onError } from '../../../libs/errorLib';
import { AppsService } from '../../../services/workflow/system/apps.service';

const AddEditApp = ({ handleClose, id = null }) => {
    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
            description: yup.string().required(),
        })
        .required();

    const { control, register, handleSubmit, setValue, formState, getValues } =
        useForm({
            resolver: yupResolver(schema),
            mode: 'all',
            defaultValues: {
                name: '',
                description: '',
            },
        });

    const [selectedWorkflowApp, setSelectedWorkflowApp] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                if (id) {
                    setSelectedWorkflowApp(await AppsService.getApp(id));
                }
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    const onSubmit = async (form) => {
        setIsLoading(true);

        id ? handleUpdateApp(form) : handleCreateApp(form);
    };

    const isFormValid = () => {
        return formState.isValid;
    };

    useEffect(() => {
        if (selectedWorkflowApp) {
            updateFields();
        }
    }, [selectedWorkflowApp]);

    const updateFields = async () => {
        setValue('name', selectedWorkflowApp.name, {
            shouldValidate: true,
        });
        setValue('description', selectedWorkflowApp.description, {
            shouldValidate: true,
        });
    };

    const handleCreateApp = async (form) => {
        const body = {
            name: form.name,
            description: form.description,
        };

        try {
            await AppsService.create(body);
            setIsLoading(false);
            handleClose(true);
        } catch (e) {
            setIsLoading(false);
            return onError(e.message);
        }
    };

    const handleUpdateApp = async (form) => {
        const body = {
            name: form.name,
            description: form.description,
        };

        try {
            await AppsService.update(id, body);
            setIsLoading(false);
            handleClose(true);
        } catch (e) {
            setIsLoading(false);
            return onError(e.message);
        }
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Workflow App</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            render={(field) => (
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    autoFocus
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="description"
                            control={control}
                            render={(field) => (
                                <TextField
                                    fullWidth
                                    data-testid="description"
                                    name="description"
                                    label="Description"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <LoaderButton
                    type="submit"
                    size="lg"
                    data-testid="updateAppBtn"
                    isLoading={isLoading}
                    disabled={!isFormValid()}>
                    {id ? 'Update App' : 'Create App'}
                </LoaderButton>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClose(true)}>
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditApp;
