import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import { Controller } from 'react-hook-form';

import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    TextField,
    FormGroup,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useCreateCompany from './hooks/useCreateCompany';

interface CreateCompanyProps {
    show: boolean;
    handleClose: (newCompanyData: any) => void;
}

const CreateCompany = ({ show = false, handleClose }: CreateCompanyProps) => {
    const {
        control,
        setChecked,
        isFormValid,
        onSubmit,
        isLoading,
        handleSubmit,
    } = useCreateCompany({ handleClose });

    return (
        <Dialog open={show} onClose={handleClose} data-testid="edit-user-modal">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Create Company</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: '10px' }}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <Controller
                                name="companyName"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="Company Name"
                                        variant="outlined"
                                        data-testid="companyName"
                                        name="companyName"
                                        autoFocus
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <Controller
                                name="companyId"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        size="small"
                                        label="Company ID"
                                        data-testid="companyId"
                                        name="companyId"
                                        fullWidth
                                        variant="outlined"
                                        autoFocus
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <Controller
                                name="platform"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        size="small"
                                        label="Platform"
                                        fullWidth
                                        variant="outlined"
                                        data-testid="platform"
                                        name="platform"
                                        autoFocus
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup className="d-flex">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="setupThirdPartyAuth"
                                        data-testid="setupThirdPartyAuth"
                                        onChange={setChecked}
                                        autoFocus
                                    />
                                }
                                label="Setup Third Party Authorization"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <LoaderButton
                            onClick={handleSubmit(onSubmit)}
                            fullWidth
                            variant="contained"
                            type="submit"
                            data-testid="createCompanyBtn"
                            isLoading={isLoading}
                            disabled={!isFormValid()}>
                            Create Company
                        </LoaderButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CreateCompany;
