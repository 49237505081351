import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    IconButton,
    Button,
    List,
    ListItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CompanyKeySecretModalProps {
    show: boolean;
    handleClose: (_: boolean) => void;
    newCompanyResponse: any;
}

const CompanyKeySecretModal = ({
    show = false,
    handleClose,
    newCompanyResponse = null,
}: CompanyKeySecretModalProps) => {
    return (
        <Dialog open={show} onClose={handleClose} data-testid="edit-user-modal">
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>IMPORTANT Key and Secret</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <p>
                    Please make note of the values below.{' '}
                    <b>These cannot be generated again</b> and will be needed to
                    access the Datacor API.
                </p>
                <List>
                    <ListItem>
                        <b>Client Key: </b>
                        <br />
                        {newCompanyResponse?.verification?.clientId}
                    </ListItem>
                    <ListItem>
                        {' '}
                        <b>Client Secret: </b>
                        <br />
                        {newCompanyResponse?.verification?.appClientSecret}
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose(true)}>
                    Understood
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompanyKeySecretModal;
