import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Draggable } from '@fullcalendar/interaction';
import { RootState } from '../../../store';

const useNotifications = () => {
    const { schedulerDataSet } = useSelector(
        (state: RootState) => state.scheduler
    );
    const unscheduledSteps = schedulerDataSet.unscheduledSteps;
    const schedulingErrors = schedulerDataSet.schedulingErrors;
    const [filteredUnscheduledSteps, setFilteredUnscheduledSteps] = useState(
        []
    );

    useEffect(() => {
        setFilteredUnscheduledSteps(unscheduledSteps);
    }, [unscheduledSteps]);

    useEffect(() => {
        setTimeout(() => {
            let draggableEl = document.getElementById('external-events');
            if (draggableEl) {
                new Draggable(draggableEl, {
                    itemSelector: '.fc-event-main',
                    eventData: (eventEl) => {
                        return {
                            BatchNumber: eventEl.getAttribute('batchnumber'),
                            id: eventEl.getAttribute('id'),
                            Workcenter: eventEl.getAttribute('workcenter'),
                            StepNumber: eventEl.getAttribute('stepnumber'),
                            backgroundColor:
                                eventEl.getAttribute('backgroundcolor'),
                        };
                    },
                });
            }
        });
    }, [schedulerDataSet.warehouseId]);

    const filterItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        const results = unscheduledSteps.filter((step: any) => {
            return (
                step.batchTicket.BatchNumber.toString()
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase()) ||
                (step.Description
                    ? step.Description.toString()
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())
                    : false) ||
                (step.batchTicket.ProductName
                    ? step.batchTicket.ProductName.toString()
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())
                    : false) ||
                (step.batchTicket.Pnum
                    ? step.batchTicket.Pnum.toString()
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())
                    : false) ||
                (step.batchTicket.SalesOrder
                    ? step.batchTicket.SalesOrder.toString()
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())
                    : false)
            );
        });

        setFilteredUnscheduledSteps(results);
    };
    return {
        unscheduledSteps,
        schedulingErrors,
        filteredUnscheduledSteps,
        filterItems,
    };
};
export default useNotifications;
